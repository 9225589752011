// @file Network requests related to mention suggestions from surface

import type { VersionedFetchOptions } from '@@/surface/api/fetchable_object'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { UserMentionSuggestion, UserMentionSuggestionAttributes } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import type { FetchOptions } from '@padlet/fetch'
import { HTTPMethod } from '@padlet/fetch'

class MentionSuggestionsApi extends FetchableObject {
  public static async fetchMentionSuggestions(
    params: { wallId: number; wishId?: number; q?: string },
    fetchOptions: VersionedFetchOptions & { url?: string } = { apiVersion: 1 },
  ): Promise<UserMentionSuggestion[]> {
    const { url, apiVersion, ...otherFetchOptions } = fetchOptions
    const query: FetchOptions['query'] = {
      wallId: params.wallId.toString(),
    }
    if (params.wishId != null) query.wishId = params.wishId.toString()
    if (params.q != null) query.q = params.q
    const response: JsonAPIResponse<UserMentionSuggestionAttributes> = await fetchJson(
      url ?? this.buildUrl(this.url, { apiVersion }),
      {
        ...otherFetchOptions,
        method: HTTPMethod.get,
        query,
      },
    )
    if (!Array.isArray(response.data)) {
      throw new Error('Mention suggestions should be an array')
    }
    const suggestions: UserMentionSuggestion[] = response.data.map(({ type, attributes }) => ({
      type,
      attributes,
    }))
    return suggestions
  }

  public static get url(): string {
    return `/api/\${ apiVersion }/mention_suggestions`
  }
}

export { MentionSuggestionsApi }
